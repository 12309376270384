import React, { useState, useEffect } from 'react';
import { Heading, InlineTextButton } from '../../components';
import { richText } from '../../util/richText';
import { FormattedMessage } from '../../util/reactIntl';

import { getStoredLanguage } from '../../util/translations.js';
import translateWeglot from '../../translations/useWeglot.js';

import { calculatePostLanguage } from '../../components/TranslateButton/TranslateButton.js';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const createContent = (text) => richText(text, {
  linkify: true,
  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
  longWordClass: css.longWord,
  breakChars: '/',
});

const SectionTextMaybe = props => {
  const { text, heading, currentAuthor, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const [isTranslated, setIsTranslated] = useState(false);
  const [translationFailed, setTranslationFailed] = useState(false);

  const content = createContent(text);
  const [translatedRichText, setTranslatedRichText] = useState(content);

  // Update translatedRichText whenever the text prop changes
  useEffect(() => {
    const content = richText(text, {
      longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
      longWordClass: css.longWord,
      breakChars: '/',
    });
    setTranslatedRichText(content);
  }, [text]);

  const postLanguage = calculatePostLanguage(currentAuthor?.attributes?.profile);

  const handleTranslate = async () => {
    // This is a bit different than the TranslateButton
    // because of the richText content and the useEffect
    console.log('postLanguage', postLanguage, 'getStoredLanguage', getStoredLanguage())

    if (postLanguage === getStoredLanguage()) {
      console.log("same languages, not going to translate");
      setTranslationFailed(true);
      return;
    };

    if (!isTranslated) {
      console.log('pressed translate')
      const translated = await translateWeglot(text, postLanguage, getStoredLanguage());

      if (translated) {
        setIsTranslated(true);
        const newContent = createContent(translated);
        setTranslatedRichText(newContent);
        setTranslationFailed(false);

      } else {
        console.log("translation failed");
        setTranslationFailed(true);
      }
    } else {
      console.log('pressed show original')
      const originalContent = createContent(text);
      setTranslatedRichText(originalContent);
      setIsTranslated(false);
    }
  };

  return text ? (
    <section className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <p className={textClass}>{translatedRichText}</p>
      <div className={css.translateButtonDiv}>
        <InlineTextButton
          className={css.translateButton}
          onClick={handleTranslate}
        >
          <FormattedMessage id={translationFailed? 'ListingPage.translationFailed' : isTranslated ? 'ListingPage.showOriginal' : 'ListingPage.translate'} />
        </InlineTextButton>
      </div>
    </section>
  ) : null;
};

export default SectionTextMaybe;
